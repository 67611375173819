@import "~/src/scss/index.scss";



.repositorio-description {
    .title {
        padding: 30px;
      text-align: center;
    }
  
    .search-section {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
  
      .search-bar {
        width: 90%;
        padding: 10px 20px;
        border-radius: 20px;
        height: 48px;
        font-size: 1rem;
        border: 1px solid #ddd;
        &::after {
          content: url('../../../../assets/png/lupa.png'); // Cambia esto por la ruta a tu ícono de lupa
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateX(-50%);
        }
      }
    }
  
    .year-selector {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 10px 0;
  
      .year-button {
        background-color: $buttons;
        color: white;
        border: none;
        padding: 10px 20px;
        margin: 5px;
        border-radius: 20px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover, &.active {
          background-color: $footer;
        }
      }
    }
  
    .repositorio-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(198px, 1fr));
      grid-gap: 1rem;
      justify-content: center;
      align-items: stretch;
      padding: 40px;
  
      .repositorio-card {
        cursor: pointer;
        perspective: 1000px;
  
        .repositorio-image-container {
          width: 198px;
          height: 280px;
          position: relative;
          border-radius: 10px;
          transition: transform 0.6s;
          transform-style: preserve-3d;
  
          .repositorio-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            transition: transform 0.6s;
            backface-visibility: hidden;
          }
  
          .repositorio-card-back {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #001b35;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            backface-visibility: hidden;
            transform: rotateX(180deg);
            padding: 10px;
            border-radius: 10px;
            overflow: hidden;
            font-size: adjust-font(); // Asegúrate de ajustar el tamaño de la fuente correctamente
          }
        }
  
        &:hover .repositorio-image-container {
          transform: rotateX(180deg);
        }
      }
    }
  
    @media (max-width: 768px) {
      .repositorio-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }
    }
  }
  