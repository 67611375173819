@import "~/src/scss/index.scss";



.ui.menu.admin-menu {
  > .item {
    display: flex;
    color: $primary;
    padding: 15px 20px !important;
    margin: 0 !important;
    border-radius: 0 !important;



    &:hover,
    &.active,
    &.active:hover {
      color: $primary;
      background-color: $split-background;
    }

    > i {
      margin: 0 !important;
      margin-right: 10px !important;
    }
  }
}