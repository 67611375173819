@import "~/src/scss/index.scss";

.per-banner {
    height: 454px; // Asegúrate de especificar una unidad de medida.
    background-size: cover; // Esto asegura que el contenido siempre cubra completamente el área del contenedor.
    background-position: center; // Centra la imagen en el contenedor.
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; // Asegúrate de que el contenedor ocupe todo el ancho disponible.
}

@media (max-width: 1550px ){
    .per-banner{
        height: 350px;
    }    
}