@import "~/src/scss/index.scss";

.per-description {
    padding-top: 30px;
  display: flex;
  flex-direction: column;
  height: auto; // Cambiado de 750px a auto para adaptarse al contenido
  margin: auto;

  &__definition,
  &__vision {
    display: flex;
    flex-direction: row; // Los elementos hijo estarán en fila
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    height: auto; // Cambiado de 50% a auto para adaptarse al contenido
  }

  &__definition__left {

    width: 50%;
    display: flex;
    align-items: center; // Centrar verticalmente
    justify-content: flex-end; // Alinear horizontalmente a la derecha

    img {
      border-radius: 8px;
      max-width: 100%;
      height: auto;
    }

  }
  &__vision__right {
    width: 50%;
    display: flex;
    align-items: center; // Centrar verticalmente
    justify-content: flex-start; // Alinear horizontalmente a la izquierda

    img {
      border-radius: 8px;
      max-width: 100%;
      height: auto;
    }
  }

  &__definition__right {
    width: calc(50% - 30px);
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; // Cambiado a flex-start para alinear a la izquierda
    justify-content: center; // Centrar verticalmente

    h1, p {
      font-family: 'Poppins', sans-serif;
      text-align: left !important; // Asegura que el texto se alinee a la izquierda
      align-self: stretch; // Ocupa todo el ancho posible
    }

  }
  &__vision__left {
    width: calc(50% - 30px);
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; // Cambiado a flex-start para alinear a la izquierda
    justify-content: center; // Centrar verticalmente

    h2, p {
      font-family: 'Poppins', sans-serif;
      text-align: right; // Asegura que el texto se alinee a la izquierda
      align-self: stretch; // Ocupa todo el ancho posible
    }
  }

  // Media queries para comportamiento responsive
  @media (max-width: 768px) {
    &__definition,
    &__vision {
      flex-direction: column; // Cambia la dirección de los flex items a columna
      align-items: center; // Centra los elementos en el eje cruzado

      img {
        max-width: 80%; // Reduce el tamaño de la imagen
      }
    }

    &__definition__left, 
    &__vision__right,
    &__definition__right,
    &__vision__left {
      width: 100%; // Ocupa el 100% del ancho disponible
      margin: 10px 0; // Ajusta los márgenes para dispositivos móviles
      justify-content: center; // Centra el contenido horizontalmente
    }
  }
}
