@import "~/src/scss/index.scss";

.lineamientosObjetivos {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 20px;

  .objetivosIconos {
    display: flex;
    max-width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-x: auto; // Habilita el desplazamiento horizontal para el carrusel

    img {
      height: auto; // Mantén la altura automática para preservar la proporción
      cursor: pointer;
      margin: 10px 5px; // Espaciado vertical y horizontal
    }
  }

  .objetivosDetalle {
    padding-top: 40px;
    display: none; // Inicialmente oculto
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; // Centra el texto horizontalmente
    margin: 20px;
    width: 100%;

    &.activo {
      display: flex; // Se muestra cuando se activa
    }

    .objetivosTexto {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      text-align: justify;
      margin-bottom: 20px; // Espacio antes de la imagen
      width: calc(100% / 3); // Ajusta según necesites
    }

    .objetivosImagen img {
      max-width: 100%;
      height: auto; // Mantiene la proporción
      margin: 0 auto; // Centra horizontalmente
    }
  }

  .slider-container {
    width: 100%;
    margin: 0 auto;
    .slick-prev, .slick-next {
      display: block !important; // Asegúrate de que se muestren y sobrescriban los estilos por defecto
    }
    padding-bottom: 20 px ;
  }

  .slick-slide {
    &:focus {
      outline: none; // Elimina el contorno que podría aparecer al hacer clic
      background: none; // Elimina el fondo
    }
    
    &.slick-current,
    &.slick-active {
      background: none !important; // Asegúrate de que no se aplique ningún fondo
    }
  }

  .slick-prev,
  .slick-next {
    display: block !important;
    position: absolute;
    top: 50%; // Centra las flechas verticalmente
    z-index: 10; // Asegúrate de que estén sobre los otros elementos
  
    &::before { // Slick usa el pseudo-elemento ::before para las flechas
      font-size: 20px; // Tamaño del ícono de la flecha
      color: black; // Color del ícono de la flecha
      opacity: 1; // Asegúrate de que no esté transparente
    }
  }

  .slick-prev {
    left: -25px; // Ajusta la posición hacia la izquierda
  }

  .slick-next {
    right: -25px; // Ajusta la posición hacia la derecha
  }

  
  

  
}

.slick-slide,
.slick-slide * { // Esto apunta a todos los elementos dentro de .slick-slide
  &:focus,
  &:active {
    background: transparent !important;
    outline: none !important;
  }
}

// Estilos específicos para el slide actual/activo
.slick-slide.slick-active,
.slick-slide.slick-current {
  background: transparent !important;
}


@media screen and (max-width: 1920px) {
  .lineamientoObjetivos {
    .slider-container {
      max-width: none;
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .lineamientosObjetivos .objetivosDetalle .objetivosTexto {
    width: 100%; // Ajusta para pantallas pequeñas
  }
  
}
