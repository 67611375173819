@import "~/src/scss/index.scss";

.top-bar {
    height: 70px; 
    background-color: #ffffff;
    z-index: 10;
    position: relative;
    > .ui.container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
    }

    &__left {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 80px;

        .logo-container { 
            display: flex;
            align-items: center;
            justify-content: center;
                  
            .logo {
                height: 50px;
                margin: 10 px 0;

            }
        }

        
    }

    &__right{
        .menu {

            a{
                margin: 0 5px;
                color: $text;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                text-align: center;
                padding: 8px;
                &:hover{
                    opacity: 0.6;
                }
                
            }
            justify-content: center;
            margin-right: 80px;
        }
    }

    .hamburger-menu {
        display: none; // Por defecto no se muestra
        cursor: pointer;
        // Estilos del menú hamburguesa (barras)
        .bar {
            width: 25px;
            height: 3px;
            background-color: $footer;
            margin: 5px 0;
            transition: 0.4s;
        }
    }
    
    .menu {
        // Estilos para animación y despliegue del menú
        
        transition: transform 0.3s ease-in-out;
        
        &.visible {
            transform: translateX(0);
        }
    }
    

    @media (max-width: 1000px) { 
        .hamburger-menu {
            display: block; 
        }
        .menu {
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            width: 250px; // Ajusta el ancho del menú desplegado
            background-color: #fff; // Ajusta el color de fondo según tu diseño
            box-shadow: 4px 0 5px rgba(0,0,0,0.5);
            display: flex;
            flex-direction: column;
            // Esconde el menú fuera de la vista
            transform: translateX(-100%);
        }
    }


}
