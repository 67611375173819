.powerbi-page {
  &__add {
    display: flex;
    justify-content: flex-end;

    > .ui.button{
      margin: 0;
    }
  }
  .ui.tab.segment {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  }