
$primary: #FFFFFF;

$primary-hover: #EFF6FF;
$background-dark: #001b35;

// text

$text: #000000;

//placeholder

$split-background: #1B43B2;
$split-text: #FFFFFF;




//Botones

$buttons: #1951DC;



// Footer

$footer: #162655;
$footer-text: #FFFFFF;


// social color

$youtube: #FFFFFF;
$facebook: #FFFFFF;
$tiktok: #FFFFFF;