@import "~/src/scss/index.scss";

.info__inicio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto; 
  padding: 20px; 
  background-color: $split-background;
  color: white;

  h3, h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 45px;
    text-align: center;
    margin: 10px 0; // Añade un poco de margen para separar los titulares
  }

  h3 {
    font-weight: 400;
  }

  h1 {
    font-weight: 700;
  }
}

// Media query para pantallas pequeñas
@media (max-width: 768px) {
  .info__inicio {
    h3, h1 {
      font-size: 24px; // Reduce el tamaño de la fuente para pantallas pequeñas
    }
  }
}
