@import "~/src/scss/index.scss";

$widthLeftMenu: 200px;

.admin-layout {
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    background-color: rgb(238, 228, 228);

    &__left {
        background-color: $background-dark;
        width: $widthLeftMenu;

        &-logocontainer{
            display: flex;
            align-items: center;
            justify-content: center;
                  
            .logo {
                height: 60px;
                margin: 10px 0;
            }
        }

    }

    &__right {
        width: calc(100% - $widthLeftMenu);
        &-header {
            background-color: $background-dark;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 35px;
        }

        &-content {
            position: relative;
            margin: 20px;
        }
    }
}