@import "~/src/scss/index.scss";

.prioridades-objetivos {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 20px;

  .objetivosIconos {
    display: flex;
    max-width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-x: auto; // Habilita el desplazamiento horizontal para el carrusel

    img {
      height: auto; // Mantén la altura automática para preservar la proporción
      cursor: pointer;
      margin: 10px 5px; // Espaciado vertical y horizontal
    }
  }

  .objetivosDetalle {
    padding-top: 40px;
    display: flex;
    flex-direction: row; // Alinea los elementos horizontalmente
    align-items: center; // Centra verticalmente
    justify-content: center; // Espacio igual entre los elementos
    width: 100%;

    &.activo {
      display: flex; // Se muestra cuando se activa
    }

    .objetivosTexto {
      font-family: 'Poppins';
      font-size: 16px;
      text-align: justify;
      flex: 1; // Ocupa la mitad del espacio disponible
      padding: 0 20px;
    }

    .objetivosImagen img {
        flex: 1; // Ocupa la mitad del espacio disponible
        img {
          width: 100%; // Asegura que la imagen ocupe todo el espacio disponible
          height: auto; // Mantiene la proporción de la imagen
        }
    }
  }

  .slider-container {
    width: 100%;
    margin: 0 auto;
    .slick-prev, .slick-next {
      display: block !important; // Asegúrate de que se muestren y sobrescriban los estilos por defecto
    }
    padding-bottom: 20 px ;
  }

  .slick-slide {
    &:focus {
      outline: none; // Elimina el contorno que podría aparecer al hacer clic
      background: none; // Elimina el fondo
    }
    
    &.slick-current,
    &.slick-active {
      background: none !important; // Asegúrate de que no se aplique ningún fondo
    }
  }

  .slick-prev,
  .slick-next {
    display: block !important;
    position: absolute;
    top: 50%; // Centra las flechas verticalmente
    z-index: 10; // Asegúrate de que estén sobre los otros elementos
  
    &::before { // Slick usa el pseudo-elemento ::before para las flechas
      font-size: 20px; // Tamaño del ícono de la flecha
      color: black; // Color del ícono de la flecha
      opacity: 1; // Asegúrate de que no esté transparente
    }
  }

  .slick-prev {
    left: -25px; // Ajusta la posición hacia la izquierda
  }

  .slick-next {
    right: -25px; // Ajusta la posición hacia la derecha
  }

  
  

  .objetivosPowerBi {
    width: 100%;
    max-width: 1920px;
    height: 0; // Inicialmente oculto, se ajusta según el contenido
    overflow: hidden;
    transition: height 0.3s ease;

    &.activo {
      height: 1080px; // Ajusta la altura cuando se activa
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.slick-slide,
.slick-slide * { // Esto apunta a todos los elementos dentro de .slick-slide
  &:focus,
  &:active {
    background: transparent !important;
    outline: none !important;
  }
}

// Estilos específicos para el slide actual/activo
.slick-slide.slick-active,
.slick-slide.slick-current {
  background: transparent !important;
}


@media screen and (max-width: 1920px) {
  .prioridades-objetivos  {
    .slider-container {
      max-width: none;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1320px) {
  .prioridades-objetivos  .objetivosDetalle .objetivosTexto {
    width: 100%; // Ajusta para pantallas pequeñas
  }
  .prioridades-objetivos {
    padding: 0; // Si no quieres el padding en pantallas pequeñas
    // Resto del código
  }
}

@media screen and (max-width: 768px) {
  .prioridades-objetivos  .objetivosDetalle .objetivosTexto {
    width: 100%; // Ajusta para pantallas pequeñas
  }
  .prioridades-objetivos {
    padding: 0; // Si no quieres el padding en pantallas pequeñas
    // Resto del código
  }
}
