@import "~/src/scss/index.scss";

.auth {
    background-color: lightblue;
    min-height: 100vh;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;

    .logo{
        width: 150px;
        height: 100%;
        margin-bottom: 30px;
    }

    &__form {
        width: 450px;
        .ui.menu{
            .item {
                width: 100%;
                justify-content: center;
                background-color: #fff !important;
                border-radius: 0% !important;
                border-top: 4px solid grey !important;
                font-size: 18px;
                &.active{
                    font-weight: normal;
                    border-radius: 0% !important;
                    border-top-color: $split-background !important;
                }
            }
        }
    }
}