@import "~/src/scss/index.scss";

.socios-inicio {
    height: auto !important; // Cambiado a auto para adaptarse al contenido en pantallas pequeñas

    &__title {
        padding-top: 66px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto; // Ajustado a auto para adaptabilidad

        &__socios{
            margin-left: 20px;
            width: 254px;
            height: 73px;
            border-radius: 52px;
            background: #1b43b2;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            text-align: center;
            position: relative;
        }
    }

    &__logos {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto; // Cambiado a auto para adaptarse al contenido

        &__first,
        &__second {
            height: auto; // Cambiado a auto para adaptarse al contenido
            display: flex;
            flex-direction: row; // Predeterminado para pantallas grandes
            justify-content: space-around;
            align-items: center;
            width: 100%;
            padding: 10px 0; // Añadido padding para separar los logos

            img {
                max-height: 95px;
                width: auto;
            }
        }
    }
}

// Media query para pantallas pequeñas
@media (max-width: 768px) {
    .socios-inicio {
        &__logos {
            &__first,
            &__second {
                flex-direction: column; // Cambia la dirección a columna para apilar los logos
                
                a {
                    margin-top: 20px;
                    margin-bottom: 20px; // Añade un margen abajo para separar los logos
                }
            }
        }
    }
}
