@import "~/src/scss/index.scss";

.user-form {
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    > .ui.image {
      margin: 0;
      border: 2px dashed $primary;
      padding: 5px;
      cursor: pointer;
    }
  }
}