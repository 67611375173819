@import "~/src/scss/index.scss";

.banner {
  display: flex;
  height: 971px;
  background-image: url("../../../../assets/banner-inicio.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &-left, &-right {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  &-left {
    &__logo {
      height: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 20px; // Espacio entre el logo y el texto, ajustado aquí para pantallas grandes
    }

    &__text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;

      h1 {
        font-family: 'Poppins', sans-serif;
        font-size: 2.1em;
        margin-bottom: 20px; // Espacio entre el título y el párrafo, para pantallas grandes
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-size: 1.5em;
        font-weight: 450;
      }
    }

    &__logo__image {
      max-width: 730px; // Ancho máximo para el logo
      max-height: 300px; // Altura máxima para el logo, ajusta según sea necesario
      height: auto;
      margin-bottom: 20px; // Espacio entre el logo y el texto
    }
  }

  &-right {
    display: flex;
    justify-content: center;
    align-items: center;

    &__image {
      max-width: 730px; // Ancho máximo para la imagen de la derecha
      max-height: 692px; // Altura máxima para la imagen de la derecha
      width: auto;
      height: auto;
      border-radius: 30px;
    }
  }
}

@media (max-width: 1500px) {

    

  .banner {

    height: auto;
    

    &-left, &-right {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &-left{

      &__logo {
        height: auto;
      }

      &__text {
        h1 {
          font-size: 1.8em; 
        }

        p {
          font-size: 1.2em; 
        }
      }

      &__logo__image {
        max-width: 70%; 
        margin-bottom: 10px; 
      }
    }

    &-right{

      &__image {
        max-width: 80%;
        max-height: 80%;
      }
    }
  }
}

// Media queries para pantallas medianas
@media (max-width: 1200px) {
  .banner {
    height: auto;
    flex-direction: column;

    &-left, &-right {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &-left {
      &__logo {
        height: auto;
      }

      &__text {
        h1 {
          font-size: 1.8em; 
        }

        p {
          font-size: 1.2em; 
        }
      }

      &__logo__image {
        max-width: 80%; 
        margin-bottom: 10px; 
      }
    }

    &-right {
      &__image {
        max-width: 80%; 
        max-height: 80%; 
      }
    }
  }
}


@media (max-width: 768px) {
  .banner-left__text {
    text-align: justify; 
  }
}
