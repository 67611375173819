@import "~/src/scss/index.scss";


.logo-footer{
    height: 100%;
    width: 25%;
    position: relative;

    &__image{

        height: 88px;
        width: 293px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        
    }

    @media (max-width: 1330px) {
        width: 25%;


    }
    @media (max-width: 977px) {
        
        &__image{
            width: 200px;
            height: auto;
        }


    }

    
}