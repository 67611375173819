@import "~/src/scss/index.scss";
$breakpoint-mobile: 768px; // Este es el punto de ruptura para móviles

.client-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; // Cambiado para espaciado entre contenido principal y footer
    width: 100%;

    &__footer {
        padding: 20px 0;
        border-top: 3px solid $background-dark;
        background-color: $footer;
        color: $footer-text;

        > .ui.container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; // Permite que los elementos se ajusten en pantallas pequeñas
            align-items: flex-start;
            width: 100%;

            &:last-of-type {
                width: 100%;
                justify-content: center;
                margin-top: 30px;
                color: $primary;
                font-size: 15px;
                align-items: center;
            }
                

            @media (max-width: $breakpoint-mobile) {
                flex-direction: column;
                align-items: center;
            }
            
        }


.logo-footer {
    // ... Tus estilos actuales para logo-footer...
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
  
    &__image {
      // Estilos para el logo, posiblemente responsive
      @media (max-width: $breakpoint-mobile) {
        max-width: 350px;
        height: auto;
      }
    }
}

.bottom-container {
    display: flex; // Usa flexbox
    justify-content: center;
    align-items: center; // Centra verticalmente
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    width: 100%;

    .span {
        align-items: center;
    }

    @media (max-width: $breakpoint-mobile) {
        padding-top: 5px;
        flex-direction: column;
    }
}
}}