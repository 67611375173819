@import "~/src/scss/index.scss";

.mapa-footer{
    height: 100%;
    width: 25%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alinea los ítems a la izquierda */
    color: white; /* Establece el color de texto a blanco */

    &__title{
        
        padding-top: 20px;
        padding-bottom: 10px;
        width: 100%; /* Asegura que el título tenga el ancho completo para centrar el texto */
        text-align: left; /* Alinea el título a la izquierda */
        font-size: 1.2em;
        width: 100%;
    }

    &__links {
        display: flex;
        flex-direction: column;
        width: 100%; /* Asegura que los enlaces usen el ancho completo */
        padding-left: 0; /* Alinea los enlaces al mismo margen que el título */
    }

    a {
        color: white !important; /* Asegura que los enlaces sean de color blanco */
        text-decoration: none !important; /* Opcional: elimina el subrayado de los enlaces */
        padding: 5px 0; /* Opcional: añade relleno a cada enlace para espaciar */
        margin-left: 0; /* Alinea los enlaces al borde izquierdo del contenedor */
        font-size: 0.9em;
    }

    a:hover {
        text-decoration: underline; /* Opcional: añade un subrayado al pasar el ratón por encima */
    }
}
