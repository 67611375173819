@import "~/src/scss/index.scss";

.social-footer {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-right: 30px;
  position: relative;

  &__title {
    margin-bottom: 10px;
    font-size: 1.2em;
    padding-top: 20px;
    width: 100%;
    text-align: center; // Asegúrate de que el título esté centrado
  }

  &__links {
    display: flex;
    justify-content: center; // Alinea los elementos horizontalmente en el centro
    right: 50%;
    transform: (50%);

    .social-footer__link { // Clase para los enlaces individuales
      font-size: 1.5em; // Tamaño de los íconos
      margin: 0 15px; // Espaciado entre los íconos
      color: white; // Color de los íconos

      &:hover {
        color: #ccc; // Color de los íconos al pasar el mouse por encima
      }
    }
  }
}
