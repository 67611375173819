.menu-page {
    &__add {
      position: absolute;
      right: 0;
      top: 0;
  
      > .ui.button {
        margin: 0;
      }
    }
  }