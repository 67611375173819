@import "~/src/scss/index.scss";

.repositorio-form {
  .file-upload-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;

    .upload-circle {
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px dashed $primary;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      
      .upload-icon {
        font-size: 2em;
        color: $primary;
      }
      
      .preview-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .upload-pdf-button {
      button {
        padding: 10px 20px;
        background-color: $footer;
        color: white;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 0.9em;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
        
        &:hover {
          background-color: darken($footer, 10%);
        }
      }
    }
  }
  
  // Resto de estilos
}
